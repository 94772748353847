import { FC } from "react";
import { BenefitsCardType, i18translateType } from "../../../@types/types-body";
import { useTranslation } from "next-i18next";

export const BenefitsCard: FC<BenefitsCardType> = ({
  number,
  title,
  text,
  namespace,
}) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <div className="mx-auto text-center justify-self-center px-4">
      <h2 className="bg-crowlyn-light w-16 h-16 mx-auto rounded-full text-2xl text-center align-middle leading-[4rem] text-white font-bold">
        {number}
      </h2>
      <h3 className="text-crowlyn-mid leading-normal text-xl m-0 font-bold">
        {t(`${title}`)}
      </h3>
      <p className="leading-relaxed mt-2.5 mb-[34px] text-justify">
        {t(`${text}`)}
      </p>
    </div>
  );
};
