import { FC } from "react";
import { useTranslation } from "next-i18next";
import { i18translateType, MoreInfoType } from "../../@types/types-body";
import Link from "next/link";

export const MoreInfoSection: FC<MoreInfoType> = ({
  id,
  linkTitle,
  linkText,
  link,
  namespace,
}) => {
  const { t }: i18translateType = useTranslation(namespace);
  return (
    <div className="flex w-full mt-8">
      <p
        key={id}
        className="font-medium text-secondary-light leading-relaxed text-lg lg:w-7/12 mx-auto text-center"
      >
        {t(`${linkTitle}`)}
        <Link
          className="font-bold leading-none text-crowlyn hover:text-crowlyn-light relative py-[3px] px-[5px] before:absolute before:content-[''] before:w-full before:scale-x-100 before:origin-right before:bg-gray-350 before:transition-transform before:duration-600 before:delay-300 before:ease-in-expo before:bottom-0 before:left-0 before:h-px after:absolute after:content-[''] after:w-full after:scale-x-0 after:origin-left after:bg-primary after:transition-transform after:duration-600 after:delay-75 after:ease-in-expo after:bottom-0 after:left-0 after:h-px hover:before:scale-x-0 hover:after:scale-x-100 hover:after:delay-300 hover:before:delay-75"
          href={link}
        >
          {t(`${linkText}`)}
          <i className="far fa-long-arrow-right ml-[5px]"></i>
        </Link>
      </p>
    </div>
  );
};
