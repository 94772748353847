import { FC } from "react";
import { MoreInfoSection } from "@components/dividers/more-info-section";
import { ContainerDividerSpanSecond } from "@components/dividers/container-divider-span-second";
import { BenefitsCard } from "@components/sections/benefits/benefits-card";
import { useTranslation } from "next-i18next";
import {
  i18translateType,
  PagePropsBasicType,
} from "../../../@types/types-body";

const BenefitsHome: FC<PagePropsBasicType> = ({ namespace }) => {
  const { t }: i18translateType = useTranslation();
  return (
    <section id="cooperation" className="my-24">
      <ContainerDividerSpanSecond
        id={"home-1"}
        title={"benefits.title"}
        text={"benefits.text"}
        span={"benefits.span"}
        namespace={namespace}
        subtitle={"benefits.subtitle"}
      />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 ">
          <BenefitsCard
            number={1}
            namespace={namespace}
            text={"benefits.card.1.text"}
            title={"benefits.card.1.title"}
          />
          <BenefitsCard
            number={2}
            namespace={namespace}
            text={"benefits.card.2.text"}
            title={"benefits.card.2.title"}
          />
          <BenefitsCard
            number={3}
            namespace={namespace}
            text={"benefits.card.3.text"}
            title={"benefits.card.3.title"}
          />
          <BenefitsCard
            number={4}
            namespace={namespace}
            text={"benefits.card.4.text"}
            title={"benefits.card.4.title"}
          />
        </div>
      </div>
      <MoreInfoSection
        id={"home-more-info-1"}
        linkTitle={"benefits.more_info.linkTitle"}
        linkText={"benefits.more_info.linkText"}
        link={t("common:menu.path.contact")}
        namespace={namespace}
      />
    </section>
  );
};

export default BenefitsHome;
